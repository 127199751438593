import styled from '@emotion/styled';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { resizeImage } from '../../../utils/imageUtils';
import { Icon, Icons } from '../Branding';
import IconDescriptionInput from './IconDescriptionInput';
import IconUpload from './IconUpload';
import { useGetDefaultBranding } from '../../../hooks/api/useBranding';

const Container = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
`;

interface IconsTabProps {
  payments: Icons;
  licenses: Icons;
  selectedPayments: Icons | undefined;
  selectedLicenses: Icons | undefined;
  onPaymentsChange: (paymentMethods: Icons) => void;
  onLicensesChange: (licenses: Icons) => void;
}

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const IconUploadWrapper = styled.div`
  flex: 0.9;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export function IconsTab({
  selectedPayments,
  payments,
  licenses,
  selectedLicenses,
  onPaymentsChange,
  onLicensesChange,
}: IconsTabProps) {
  const { impersonationLock } = useAuthContext();
  const { data: defaultBranding } = useGetDefaultBranding();

  const getCurrentPayment = useCallback(
    (paymentMethod: string | number) => {
      return selectedPayments && selectedPayments[paymentMethod]
        ? selectedPayments[paymentMethod]
        : payments[paymentMethod];
    },
    [selectedPayments, payments]
  );

  const getCurrentLicense = (license: string | number) => {
    return selectedLicenses && selectedLicenses[license]
      ? selectedLicenses[license]
      : licenses[license];
  };

  const handleIconChange = async (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
    name: keyof Icons,
    onChange: (updatedItems: Icons) => void,
    getCurrentItem: (itemType: string | number) => Icon
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const resizedImage = await resizeImage(selectedFile, 'xs');
      onChange({
        ...(type === 'payment' ? selectedPayments : selectedLicenses),
        [name]: {
          ...getCurrentItem(name),
          icon: resizedImage || '',
        },
      });
    }
  };

  const handleResetPaymentsIcon = (name: keyof Icons) => {
    const defaultIcon = defaultBranding?.payments[name].icon;
    onPaymentsChange({
      ...selectedPayments,
      [name]: {
        ...getCurrentPayment(name),
        icon: defaultIcon,
      },
    });
  };

  const handleResetLicensesIcon = (name: keyof Icons) => {
    const defaultIcon = defaultBranding?.licenses[name].icon;
    onLicensesChange({
      ...selectedLicenses,
      [name]: {
        ...getCurrentLicense(name),
        icon: defaultIcon,
      },
    });
  };

  const handlePaymentsIconChange = (
    e: ChangeEvent<HTMLInputElement>,
    paymentMethod: keyof Icons
  ) => {
    handleIconChange(
      e,
      'payment',
      paymentMethod,
      onPaymentsChange,
      getCurrentPayment
    );
  };

  const handleLicensesIconChange = (
    e: ChangeEvent<HTMLInputElement>,
    license: keyof Icons
  ) => {
    handleIconChange(
      e,
      'license',
      license,
      onLicensesChange,
      getCurrentLicense
    );
  };

  const handleOnInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
    name: string,
    iconsChanged: Icons | undefined
  ) => {
    const onChangeFn = type === 'payment' ? onPaymentsChange : onLicensesChange;
    const key = type === 'payment' ? 'payments' : 'licenses';
    const defaultIcon = defaultBranding?.[key][name].icon || {};
    onChangeFn({
      ...iconsChanged,
      [name]: {
        ...defaultIcon,
        ...(iconsChanged ? iconsChanged![name] : ({} as Icon)),
        description: e.target.value,
      },
    });
  };

  const BASE_ASSET_URL = process.env.REACT_APP_PUBLIC_SEARCH_URL;

  return (
    <Container>
      <Box>
        <Typography variant="subtitle2" mb={10}>
          Payment options
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {Object.keys(payments)
          .sort()
          .map((paymentMethod) => {
            const currentPaymentMethod = getCurrentPayment(paymentMethod);
            const onResetIcon = () => {
              handleResetPaymentsIcon(paymentMethod);
            };
            return (
              <StyledBox key={paymentMethod}>
                <IconUploadWrapper>
                  <IconUpload
                    disabled={impersonationLock}
                    label={paymentMethod}
                    name={paymentMethod}
                    src={
                      currentPaymentMethod.icon === undefined ||
                      currentPaymentMethod.icon.includes('assets')
                        ? `${BASE_ASSET_URL}/${currentPaymentMethod.icon}`
                        : currentPaymentMethod.icon
                    }
                    handleIconChange={(e) =>
                      handlePaymentsIconChange(e, paymentMethod)
                    }
                    onResetIcon={
                      !currentPaymentMethod.icon?.includes('assets')
                        ? onResetIcon
                        : undefined
                    }
                  />
                  <IconDescriptionInput
                    name={paymentMethod}
                    value={currentPaymentMethod.description || ''}
                    disabled={impersonationLock}
                    onChange={(e) =>
                      handleOnInputChange(
                        e,
                        'payment',
                        paymentMethod,
                        selectedPayments
                      )
                    }
                  />
                </IconUploadWrapper>
              </StyledBox>
            );
          })}
      </Grid>
      <Box>
        <Typography variant="subtitle2" mb={10}>
          Licenses
        </Typography>
      </Box>
      <Divider />
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {Object.keys(licenses)
          .sort()
          .map((license) => {
            const currentLicense = getCurrentLicense(license);
            const onResetIcon = () => {
              handleResetLicensesIcon(license);
            };
            return (
              <StyledBox key={license}>
                <IconUploadWrapper>
                  <IconUpload
                    label={license}
                    name={license}
                    disabled={impersonationLock}
                    src={
                      currentLicense.icon === undefined ||
                      currentLicense.icon.includes('assets')
                        ? `${BASE_ASSET_URL}/${currentLicense.icon}`
                        : currentLicense.icon
                    }
                    handleIconChange={(e) =>
                      handleLicensesIconChange(e, license)
                    }
                    onResetIcon={
                      !currentLicense.icon?.includes('assets')
                        ? onResetIcon
                        : undefined
                    }
                  />
                  <IconDescriptionInput
                    name={license}
                    value={currentLicense.description || ''}
                    disabled={impersonationLock}
                    onChange={(e) =>
                      handleOnInputChange(
                        e,
                        'license',
                        license,
                        selectedLicenses
                      )
                    }
                  />
                </IconUploadWrapper>
              </StyledBox>
            );
          })}
      </Grid>
    </Container>
  );
}
