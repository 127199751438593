import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../contexts/AuthContext';
import { useApi } from './useApi';
import { queryClient } from './react-query';

export const useBranding = () => {
  const { account } = useAuthContext();
  const { makeApiRequest } = useApi();
  const { data: brandingData, isLoading: isBrandingLoading } = useQuery({
    queryKey: ['branding'],
    queryFn: () => makeApiRequest('/branding', 'GET'),
  });

  const { data: customData, isLoading: isCustomLoading } = useQuery({
    queryKey: ['custom-info', account],
    queryFn: () => makeApiRequest(`/custom/${account}.json`, 'GET'),
    enabled: !!account,
  });

  const { mutateAsync: updateBranding } = useMutation({
    mutationFn: (data: any) => makeApiRequest('/branding', 'PUT', data),
    onSuccess: () => {
      // Invalidate the branding query to refetch the latest data
      queryClient.invalidateQueries({ queryKey: ['branding'] });
      queryClient.invalidateQueries({ queryKey: ['custom-info'] });
    },
  });

  return {
    branding: brandingData,
    customInfo: customData,
    isBrandingLoading,
    isCustomLoading,
    updateBranding,
  };
};

export const useGetDefaultBranding = () => {
  const { makeApiRequest } = useApi();

  return useQuery({
    queryKey: ['default-branding'],
    queryFn: () => makeApiRequest('/branding/defaults', 'GET'),
  });
};

export const useDeleteBranding = () => {
  const { makeApiRequest } = useApi();

  const { mutateAsync: deleteBranding } = useMutation({
    mutationFn: () => makeApiRequest('/branding', 'DELETE'),
    onSuccess: () => {
      // Invalidate the branding query to refetch the latest data
      queryClient.invalidateQueries({ queryKey: ['branding'] });
    },
  });

  return { deleteBranding };
};
