import styled from '@emotion/styled';
import { Box, Button, Grid } from '@mui/material';

type IconUploadProps = {
  label: string;
  name: string;
  handleIconChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  src: string;
  disabled?: boolean;
  onResetIcon?: () => void;
};

const LogoLabel = styled.label`
  margin-bottom: 12px;
`;

const IconUpload = ({
  label,
  name,
  handleIconChange,
  src,
  disabled,
  onResetIcon,
}: IconUploadProps) => {
  return (
    <Grid item display="flex">
      <Grid
        item
        display="flex"
        flexDirection="column"
        width="60px"
        height="auto"
      >
        <Box
          sx={{
            width: '60px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            border: '1px solid #C4C4C4',
            minHeight: '60px',
          }}
        >
          {src && (
            <img
              alt="icon"
              src={src}
              style={{
                maxHeight: '60px',
                maxWidth: '60px',
                textAlign: 'center',
                margin: '5px',
              }}
            />
          )}
        </Box>

        {onResetIcon && (
          <Button
            variant="text"
            sx={{
              fontSize: '12px',
              textWrap: 'nowrap',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={onResetIcon}
          >
            Reset to default
          </Button>
        )}
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        // border="1px solid #C4C4C4"
        marginLeft="10px"
        padding="5px"
      >
        <LogoLabel htmlFor={name}>{label}</LogoLabel>
        <input
          type="file"
          id={name}
          name={name}
          accept=".png, .svg"
          onChange={handleIconChange}
          disabled={disabled}
        />
      </Box>
    </Grid>
  );
};

export default IconUpload;
